<template>
  <div>
      
    <v-layout wrap justify-center  pb-12>
<v-flex xs12 sm10 md10 lg10 text-center pb-12 data-aos="zoom-in-up" data-aos-ease="ease"
data-aos-duration="1500" data-aos-delay="500">
  <v-layout wrap justify-space-between>
   <v-flex xs12 sm12 md7 lg7 ><v-layout wrap>
      <v-flex xs12  sm12 md12 lg12 text-center  pb-12 >
       <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded }">
      <v-card-title>
        <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
          'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
          'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
        }">
          About Course
        </span>
      </v-card-title>

      <v-card-text style="text-align: justify;">
        
        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Pivot Tables</span><br>
          Summarizes and analyzes data by grouping, filtering, and calculating values for better insights. 
        </p>
        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Advanced Formulas </span><br>
          Complex calculations and data manipulation using array formulas, conditional logic (IF, SUMIF, COUNTIF), nested and mathematical functions (SUM, PRODUCT, VLOOKUP, INDEX-MATCH
        </p>
        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Dashboards  </span><br>
          A visual representation of key data and metrics using charts, graphs, and tables for quick and comprehensive data analysis. 
        </p>

        <!-- Extra content to display when expanded -->
        <div v-if="isExpanded">
          <!-- Additional content goes here -->
          
          <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Conditional Formatting </span><br>
          Allows users to format cells based on specific criteria making it easy to highlight and visualize patterns and trends in data. 
        </p>

        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Power Query </span><br>
          A data transformation and integration tool in Excel that helps retrieve, clean, reshape, and combine data from various sources for analysis and reporting purposes.  
        </p>

       
        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: black">Power Pivot </span><br>
          A data modeling and analysis tool that allows for advanced data manipulation, creating relationships, and performing complex calculations on large datasets.
        </p>
        <p style="font-family: JostRegular; color: #1A2421">
          <span style="font-family: OutfitBold; color: #1A2421">Power BI</span><br>
          In this step the learner will continue to expand their  vocabulary - even on topics that aren’t 
          necessarily used in daily life. They will talk about arts, history, and cultural events. In addition, 
          also will start making things even more interesting by
           introducing more complex grammar structures as well as presentations.Connect to multiple data sources easily, analyze data comprehensively, and create interactive reports and dashboards for engaging data presentations, enhancing organizational data-driven decision-making processes.
        </p>
        
        </div>
      </v-card-text>
      
      <!-- See More button -->
      <v-flex xs12 ml-1 class="text-left">
        <v-btn text color="#0064CB" small @click="toggleExpansion">
          <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
            mdi-arrow-right
          </v-icon>
          &nbsp;<span style="font-family: JostBold;">{{ isExpanded ? 'See Less' : 'See More' }}</span>
        </v-btn>
      </v-flex>
    </v-card>
  </v-flex>   
</v-layout>
</v-flex>
<v-flex xs12 sm12 md5 lg5 pl-0 pl-xs-0 pl-sm-0 pl-md-5 pl-lg-10 pl-xl-10>
  <v-layout wrap justify-end>
      <v-flex xs12 sm12 md12 lg12>

<v-card style="color: #1B6DC1;" class="pa-5">
  <v-card-title >
    <span  style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1;">
      Related Courses
    </span>
  </v-card-title>
  <v-flex pt-3 v-for="(item, i) in items" :key="i">
    <router-link style="text-decoration:none;" :to="item.route">
    <v-card color="#E6EFF9" style="color: #1B6DC1;" class="pa-2 ">

      <v-layout wrap justify-center>
        <!-- Image on the left -->
        <v-flex xs3 >
          <v-img :src="item.img" width="300px" height="100px"></v-img>
        </v-flex>

        <v-flex xs9 pt-5>
          <v-card-text>
            <span style="font-family: OutfitBold; font-size: 18px; color: black">{{ item.title }}</span>
            <!-- Wrap the rating and span in a flex container -->
            <!-- <div style="display: flex; align-items: center;">
            
              <v-rating style="margin-left: -10px;" v-model="rating" background-color="orange lighten-3" color="orange" small half-increments></v-rating>
         

            </div> -->

          </v-card-text>
          <!-- <v-layout wrap justify-start>
            
            <v-flex xs4  pl-1>

              <v-icon small color="blue">mdi-clock-outline</v-icon>
              &nbsp; <span style="color: black; text-transform: capitalize; font-family:OutfitRegular;font-size:13px">{{ item.months }}</span>

            </v-flex>

        
            <v-flex xs8 pl-1>

              <v-icon small color="blue">mdi-book-open-outline</v-icon>
              &nbsp;<span small style="color: black; text-transform: capitalize;font-family:OutfitRegular; font-size:13px">{{ item.chapter }}</span>

            </v-flex>
          </v-layout> -->
        </v-flex>
      </v-layout>

    </v-card>
  </router-link>
  </v-flex>

</v-card>

</v-flex>
</v-layout>
</v-flex>
  </v-layout> 


</v-flex>




      
    </v-layout>
  </div>
</template>

<script>
export default {
data() {
  return {
    isExpanded: false,
    listItems: [
      "Free-tuition education in most universities (and very low fees in others).",
      "World class education by highly qualified staff.",
      "Hundreds of academic courses to choose from.",
      "Interesting country to explore with a profound history.",
      "Cultural diversity and students from all over the world.",
      "Practice speaking both English and German.",
      "Endless opportunities to work in Germany after you graduate.",
      "You can study every subject in English, no need to speak German",
      "The cost of living in Germany is affordable, averaging around 720-850€ including rent, food, transport, health insurance, telephone/internet, study materials and leisure activities.",
      "The chance to live and explore one of the world’s most developed countries and its unique culture, history and landscape.",
    ],
    // rating: 4,
    items: [
      {
          img: require("./../../assets/images/ger.png"),
          title: "German course - LEVELS A1-B2",
          route:"/coursesingleview"
          //   review: "4.5",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/office.png"),
          title: "Office Automation",
          route:"/officeautopage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/tally.png"),
          title: "Tally Prime",
          route:"/tallypage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/multimedia.png"),
          title: "Multimedia Course",
          route:"/multymediapage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
    ],
  };
},
computed: {
  // Compute the filtered list based on isExpanded
  filteredListItems() {
    if (this.isExpanded) {
      return this.listItems;
    } else {
      return this.listItems.slice(0, 5);
    }
  },
},
methods: {
  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  },
},
};
</script>

<style>
.expanded .v-card__text > div {
display: block; /* Adjust this as needed */
}
</style>y
